export const Reducer = (state, action) => {
    switch(action.type) {
        case 'busStops': {
        return action.payLoad.length < 1 ? {...state, noStopFound: true} : { ...state, busStops: action.payLoad, noStopFound: false } 
        }
        
        case 'departures': {
            return { ...state, departures: action.payLoad, stopName: action.stopName, stopId: action.stopId}
        }
        case 'depRef': {
            return {...state, departures: action.payLoad}
        }
        case 'fav': {
            return state.favStop.find(stop => stop.id === action.payLoad.id) ? {...state} : {...state, favStop: [...state.favStop, action.payLoad] }
        }
        case 'remove': {
            let arr = [...state.favStop]
            arr.splice(action.payLoad, 1)
           return {...state, favStop: arr}
        }
        case 'location':{
            return {...state, latitude: action.latitude, longitude: action.longitude}
        }
        case 'journeys':{
            return {...state, journeys: action.journeys}
        }
        case 'journey':{
            return {...state, journey: action.journey}
        }
        case 'setFromStops': {
            return {...state, stops: {...state.stops, fromStops: action.fromStops}}
        }
        case 'clearFrom': {
            return {...state, stops: {...state.stops, fromStops: []}}
        }
        case 'setToStops': {
            return {...state, stops: {...state.stops, toStops: action.toStops}}
        }
        case 'clearTo': {
            return {...state, stops: {...state.stops, toStops: []}}
        }
        default: 
        return state
    }
};


export const initialState =  JSON.parse(localStorage.getItem('busData')) || {'busStops': [], 'departures': [], 'favStop': [], 'stops':{toStops:[], fromStops:[]}, 'journeys': [{legs:[]}], 'journey':{legs:[]}, 'latitude': 52.522671, 'longitude': 13.418895, noStopFound: false};