import React from "react";
import {Link} from 'react-router-dom'

const Menu = ({ close }) => (
  <div className="menu">
    <ul>
      <li onClick={close}><Link to={'/'}><i className="fas fa-home"></i> Home</Link></li>
      <li onClick={close}><Link to={'/fav'}><i className="fas fa-heart-circle"></i> Saved Stops</Link></li>
      <li onClick={close}><Link to={'/journey-planner'}><i className="fad fa-map-marked-alt"></i> Travel Planner</Link></li>
    </ul>
  </div>
);

export default Menu