import { Link } from "react-router-dom";

const Journey = ({state}) => {
    
    return (
        <div>
            <div className="bg-bg-900 mt-3 text-white font-bold">{state.journey.legs[0].origin.name} <i className="fas fa-arrow-circle-right p-3"></i>{state.journey.legs[state.journey.legs.length-1].destination.name}</div>
            <div>{state.journey.legs.length > 0 ? state.journey.legs.map(l => <div key={l.arrival} className={`m-0.5 bg-bg-200 text-white ${l.line ? '' : 'hidden'}`}>
                <div className="p-1 ">
                    <div>
                    <div className="p-0.5 text-left">

{l.origin.name}  
</div>
                <span className=" bg-bg-500 p-1 rounded-lg">{l.line ? l.line.name : <i className="fas fa-train"></i>}
                {l.line ? 
                
                <span>
                {l.line.productName === "Bus" ? (
              <i className="fas fa-bus"></i>
            ) : (
              false
            )}{" "}
            {l.line.productName === "Tram" ? (
              <i className="fas fa-tram"></i>
            ) : (
              false
            )}{" "}
            {l.line.productName === "S" ? (
              <i className="fas fa-train"></i>
            ) : (
              false
            )}{" "}
            {l.line.productName === "RE" || l.line.productName === "ICE" || l.line.productName === "IC" || l.line.productName === "RB" ? (
              <i className="fas fa-train"></i>
            ) : (
              false
            )}{" "}
            {l.line.productName === "U" ? (
              <i className="fas fa-subway"></i>
            ) : (
              false
            )}
                </span> : ''
            }
                <span><i className="fas fa-caret-right pl-1"></i> {l.direction} </span>
                </span> 
                
                <span className="p-0.5">{l.departure ? l.departure.split('T')[1].split(':').slice(0,2).join(':') : ''}</span>
                 {l.arrivalPlatform ? <span className="pl-2">-Pl: {l.arrivalPlatform}</span> : ''}
                        </div> 
                        <div>

                <i className="fas fa-arrow-circle-down"></i> 
                        </div>
                {l.destination.name} <span className="p-0.5">{l.departure.split('T')[1].split(':').slice(0,2).join(':')}</span></div>
            </div>) 
            : <p>No Data</p>}</div>
            <Link to={'/journeys'} className="inline-block bg-bg-200 rounded-lg text-white p-2 m-1">Go Back</Link>
        </div>
    )
}

export default Journey;