import { useEffect } from "react";
import Marquee from 'react-fast-marquee'
import { Link } from "react-router-dom";
import "../../src/spinner.css";
import '../App.css';
import MyMap from "./map";


const Inputs = ({ state, dispatch }) => {  
useEffect(() => {
  const interval = setInterval(() => {
    position();
    busStops();
  }, 1000*2);
  return () => clearInterval(interval);
});
const position = () => {
    navigator.geolocation.getCurrentPosition(
      position => dispatch({
        type: 'location',
        latitude: position.coords.latitude, 
        longitude: position.coords.longitude,
      }), 
      err => console.log(err)
      );
  }

  position();

const busStops = () => {
    fetch(`https://v5.bvg.transport.rest/stops/nearby?&latitude=${state.latitude}&longitude=${state.longitude}`, {method: 'GET', mode: 'cors', headers: {}})
        .then(res => res.json())
        .then(response => response.error ? dbBusStops() :  dispatch({type: 'busStops', payLoad: response }))
        .catch(err => console.error(err));
  };
  
const dbBusStops = () => {
    fetch(`https://v5.db.transport.rest/stops/nearby?&latitude=${state.latitude}&longitude=${state.longitude}`, {method: 'GET', mode: 'cors', headers: {}})
        .then(res => res.json())
        .then(response => dispatch({type: 'busStops', payLoad: response }))
        .catch(err => console.error(err));
  };
  const departures =  (e) => {
    fetch(`https://v5.bvg.transport.rest/stops/${e.id}/departures?duration=30&linesOfStops=false&remarks=true`, {method: 'GET', mode: 'cors', headers: {}})
    .then(response => response.json())
    .then(response => response.error ? dbDepartures(e) : dispatch({type: 'departures', payLoad: response, stopName: e.name, stopId: e.id }))
    .catch(err => console.error(err));
  }

  const dbDepartures = (e) => {
    fetch(`https://v5.db.transport.rest/stops/${e.id}/departures?duration=30&linesOfStops=false&remarks=true`, {method: 'GET', mode: 'cors', headers: {}})
    .then(response => response.json())
    .then(response => dispatch({type: 'departures', payLoad: response, stopName: e.name, stopId: e.id }))
    .catch(err => console.error(err));
  }

  return (
    <div className="mt-5">
      <div className="mb-1">

      {state.noStopFound  ? <p className="bg-red-300 text-white p-1 m-1">No Stops found</p> : 
      <div>
        {state.busStops.length > 0 ? state.busStops.map(stop => <Link to={`/departures`}  key={stop.id} onClick={() => departures(stop)} className="block rounded-full border-solid border-2 border-bg-600 p-1.5 mx-auto bg-bg-300 hover:bg-bg-500 text-white list-none">
        <div className="flex text-left">
         {stop.name.length > 24 && window.innerWidth < 520 ? <Marquee style={{width: '62%'}} gradient={false} delay={'3'} pauseOnHover={true}>{'_' + stop.name + '_'}</Marquee> : <span style={{width: '62%'}}>{stop.name}</span>}
          <span className="pl-2">
          {stop.products.bus ? <i className="fas fa-bus"></i> : false}{ ' ' }
          {stop.products.tram ? <i className="fas fa-tram"></i> : false}{ ' ' }
          {stop.products.suburban ? <i className="fas fa-train"></i> : false}{ ' ' }
          {stop.products.subway ? <i className="fas fa-subway"></i> : false}{ ' ' }
          </span>
         
          <span className="absolute right-4 bg-bg-600 rounded-lg p-0.5">{stop.distance}M</span>
        </div>
          </Link>) : 
          <div>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-notch" className="spinner svg-inline--fa fa-circle-notch fa-w-16 text-white"      role="img"      xmlns="https://www.w3.org/2000/svg"      viewBox="0 0 512 512"    >      <path        fill="currentColor"        d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"      ></path>    </svg>
            <p className='text-white p-1 bg-bg-300 m-5 rounded-lg' >Searching for Stops around you</p>
          
          </div>}
      </div>
    }
      </div>
         
      <MyMap state={state} />
    </div>
  );
}

export default Inputs