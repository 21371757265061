import { Reducer, initialState } from './components/useReducer';
import JourneyPlanner from './components/journey-planner';
import Departures from './components/Departures';
import { Routes, Route } from 'react-router-dom';
import Journeys from './components/journeys';
import Journey from './components/journey';
import Inputs from './components/inputs';
import Fav from "./components/Fav";
import { useReducer } from 'react';
import Popup from 'reactjs-popup';
import BurgerIcon from "./components/BurgerIcons";
import Menu from "./components/Menu";
import './Burger-Menu.css';
import './App.css';



const contentStyle = {
  background: "transparent",
  width: "15rem",
  border: "none",
  position: 'fixed',
  top: '3rem',
  left: '3rem'
};

const App = () => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  window.addEventListener('beforeunload', () => localStorage.setItem('busData', JSON.stringify(state)));
  return (
    <div>
      <div className="">
      <Popup
      modal
      overlayStyle={{ background: "#191f46", color: 'white' }}
      contentStyle={contentStyle}
      closeOnDocumentClick={false}
      trigger={open => <BurgerIcon open={open} />}
    >
      {close => <Menu close={close} />}
    </Popup>
      </div>
      <div className="App p-2 bg-bg-600 relative">
      <h1 className="p-1 text-white text-xl font-bold">
      Travel Guide
    </h1>
    <Routes>
    <Route path="/" element={<Inputs state={state} dispatch={dispatch} />} />
    <Route path="/fav" element={<Fav state={state} dispatch={dispatch} />} />
    <Route path="/departures" element={<Departures state={state} dispatch={dispatch} />} />
    <Route path="/journey-planner" element={<JourneyPlanner state={state} dispatch={dispatch} />} />
    <Route path="/journeys" element={<Journeys state={state} dispatch={dispatch} />} />
    <Route path="/journey" element={<Journey state={state} dispatch={dispatch} />} />
    <Route path="*" element={<h1>Nothing on this page</h1>} />
  </Routes>
      </div>

      
    </div>
  );
}

export default App;
