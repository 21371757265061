import Marquee from 'react-fast-marquee';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import "../../src/spinner.css";
import React from 'react';
import '../App.css';


const  Departures = ({ state, dispatch }) => {
  const fetching = () => {
    fetch(`https://v5.bvg.transport.rest/stops/${state.stopId}/departures?duration=30&linesOfStops=false&remarks=true`, {method: 'GET', mode: 'cors', headers: {}})
    .then(response => response.json())
    .then(response => response.error ? dbFetching() : dispatch({ type: "depRef", payLoad: response, }))
    .catch(err => console.error(err));
  }
  const dbFetching = () => {
    fetch(`https://v5.db.transport.rest/stops/${state.stopId}/departures?duration=30&linesOfStops=false&remarks=true`, {method: 'GET', mode: 'cors', headers: {}})
    .then(response => response.json())
    .then(response => dispatch({ type: "depRef", payLoad: response, }))
    .catch(err => console.error(err));
  }
  const fav = () => {
    dispatch({
      type: 'fav',
      payLoad: state.busStops.find(stop => stop.id === state.stopId)
    })
  }
  useEffect(() => {
    const myInt = setInterval(fetching, 1000*30);
    return () => clearInterval(myInt);
  })
  return state.departures.length > 0 ? (
    <div >
      <span className="py-2 px-3 m-2 bg-bg-200 text-white flex justify-around">
      <span>{state.stopName}</span> <i onClick={fav} className={`${state.favStop.find(s => s.id === state.stopId) ? "fas" : "far"} fa-heart`}></i>
      </span>
      <div className="bg-bg-700 pb-4">
      {state.departures.map(departure => (
        <li
          key={departure.tripId + departure.plannedWhen}
          className="rounded-full border-solid border-2 border-bg-100 mx-auto my-0.5 bg-bg-300 text-white list-none"
        >
          <div className="flex">
          
          <span className="ml-0.2 px-0.4 bg-bg-500 rounded-lg">
          <div className='w-10 inline-block'>{departure.line.name.length < 4 ? <span>{departure.line.name}</span> : <Marquee gradient={false} delay={'2'} pauseOnHover={true} >{'_' + departure.line.name }</Marquee>} </div>
          {" "}
            {departure.line.productName === "Bus" ? (
              <i className="fas fa-bus"></i>
            ) : (
              false
            )}{" "}
            {departure.line.productName === "Tram" ? (
              <i className="fas fa-tram"></i>
            ) : (
              false
            )}{" "}
            {departure.line.productName === "S" ? (
              <i className="fas fa-train"></i>
            ) : (
              false
            )}{" "}
            {departure.line.productName === "RE" || departure.line.productName === "FEX" || departure.line.productName === "ICE" || departure.line.productName === "IC" || departure.line.productName === "RB" ? (
              <i className="fas fa-train"></i>
            ) : (
              false
            )}{" "}
            {departure.line.productName === "U" ? (
              <i className="fas fa-subway"></i>
            ) : (
              false
            )}{" "}
          </span>
            
            <span className="pl-1">
              {new Date(departure.plannedWhen)
                .toLocaleString()
                .split(",")
                .pop()
                .split(":")
                .splice(0, 2)
                .join(":")}{" "}
              {departure.delay < 0 ? <span className="text-green-300"> -{(departure.delay * -1) / 60} </span>
              : departure.delay > 0 ? <span className="text-red-500"> +{departure.delay / 60} </span>
              : false }            </span>

            <span className="pl-1 flex" style={{width: '57%'}}>
            <i className="fas fa-caret-right pt-1"></i>
            <div className='text-right'>
            {departure.direction.length > 18 && window.innerWidth < 520 ? <Marquee style={{width: '95%', marginLeft: '0.25rem', }} gradient={false} delay={'2'} pauseOnHover={true} >{" "}{ departure.direction }{" "}</Marquee> : <span style={{marginLeft: '0.25rem', }}>{departure.direction}</span> }
            </div>
            
          </span>
          </div>
        </li>
      ))}
      <button onClick={fetching} className="rounded-full border-solid border-2 border-bg-100 p-2 m-2 bg-bg-200 hover:bg-bg-500 active:bg-bg-600 text-white">Refresh</button>
      <Link
        to={"/"}
        className="rounded-full block border-solid border-2 border-bg-100 p-2 m-2 bg-bg-200 hover:bg-bg-500 active:bg-bg-600 text-white"
      >
        Go Back
      </Link>
    </div>
  </div>
  ) : (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="circle-notch"
      className="spinner svg-inline--fa fa-circle-notch fa-w-16 text-white"
      role="img"
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
      ></path>
    </svg>
  );
}

export default Departures