import { Link } from "react-router-dom";


const Journeys = ({state, dispatch}) => {
    return (<div>
        <div>
      <div>
      {state.journeys.map(j => <Link to={'/journey'} onClick={()=>dispatch({type: 'journey', journey: j})} key={j.refreshToken} className='text-white text-left flex m-1 bg-bg-200'> 
        <span className='w-1/2 pl-2 '>  <span className="bg-bg-400 px-1.5 rounded-lg">{j.legs[0].line ? j.legs[0].line.name : ''}</span> {j.legs[0].departure ? j.legs[0].departure.split('T')[1].split(':').slice(0, 2).join(':') : ''} <i className="fas fa-arrow-circle-right"></i></span> 
        <span className='w-1/3'> <span className="bg-bg-400 px-1.5 rounded-lg">{j.legs[j.legs.length - 1].line ? j.legs[j.legs.length - 1].line.name : ''}</span> {j.legs[j.legs.length-1].arrival ? j.legs[j.legs.length-1].arrival.split('T')[1].split(':').slice(0, 2).join(':') : ''} {j.legs.length > 1 ? <span className='absolute right-5'><i className="fas fa-retweet"></i></span> : ''}   </span>
      
      </Link>)} 
      <Link to={'/journey-planner'} onClick={dispatch({})} className='inline-block m-2 p-1.5 rounded-lg bg-bg-200'>Go Back</Link> 

      </div>

        </div>
    
    </div>

    )

}
export default Journeys