import TimePicker from 'react-time-picker';
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


const JourneyPlanner = ({ state, dispatch }) => {
  let nav = useNavigate();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [fromStop, setFromStop] = useState({ id: "" });
  const [toStop, setToStop] = useState({ id: "" });
  const [day, setDay] = useState('today');
  const localeHours = new Date().toLocaleTimeString();
  const [time, setTime] = useState(localeHours);
  const sp = time.split(':').splice(0, 2);
  const h = Number(sp[0]) > 12 ? Number(sp[0]) - 12 : Number(sp[0]);
  const t = `${h}:${sp[1]}${Number(sp[0])<=11?'am':'pm'}`;


  const fromStopsFinder = (value) => {
    value.trim() === ""
      ? alert("enter a valid name")
      : fetch(
          `https://v5.bvg.transport.rest/locations?poi=false&addresses=false&query=${value}`, {method: 'GET', mode: 'cors', headers: {}})
          .then((res) => res.json())
          .then((response) => dispatch({type: 'setFromStops', fromStops: response}))
          .catch((err) => console.error(err));
  };

  const toStopsFinder = (value) => {
    value.trim() === ""
      ? alert("enter a valid name")
      : fetch(
          `https://v5.bvg.transport.rest/locations?poi=false&addresses=false&query=${value}`, {method: 'GET', mode: 'cors', headers: {}} 
        )
          .then((res) => res.json())
          .then((response) => dispatch({type: 'setToStops', toStops: response}))
          .catch((err) => console.error(err));
  };
  const planner = () => {
    fromStop.id === toStop.id ? alert('select two different stops') :
       fetch(
          `https://v5.bvg.transport.rest/journeys?from=${fromStop.id}&to=${toStop.id}&departure=${day}+${t}&results=10`, {method: 'GET', mode: 'cors', headers: {}} 
        )
          .then((res) => res.json())
          .then((response) => dispatch({
            type: 'journeys',
            journeys: response.journeys
          }))
          .then(() => nav('/journeys'))
          .catch((err) => console.error(err));
  };

  return (
    <div className='mt-2'>
      
      <div className='Before-Journey-Search'>

      <div className="inputs flex flex-col mt-3">
        <hr></hr>
        <p className="text-yellow-200 bg-bg-200 font-bold">Travelling From</p>
        {fromStop.id.length > 1 ? (
          <p
            key={fromStop.id}
            className="rounded-full border-solid border-2 border-bg-600 p-1.5 mx-auto bg-bg-300 text-white w-full m-1"
          >
            {fromStop.name} <i onClick={()=>setFromStop({ id: "" })} className="absolute right-4 p-1 rounded-lg fas fa-trash-alt bg-bg-600"></i>
          </p>
        ) : (
          <span className="From m-2">
            {state.stops.fromStops.length > 0 ? <div>{
              state.stops.fromStops.map((stop) => (
                <div
                  key={stop.id}
                  className="rounded-full border-solid border-2 border-bg-600 p-1.5 mx-auto bg-bg-300 hover:bg-bg-500 text-white list-none"
                >
                  <div className="flex">
                    {stop.name.length > 25 ? (
                      <Marquee
                        style={{ width: "65%" }}
                        gradient={false}
                        delay={"3"}
                        pauseOnHover={true}
                      >
                        {"_" + stop.name + "_"}
                      </Marquee>
                    ) : (
                      <span style={{ width: "65%" }}>{stop.name}</span>
                    )}
                    <span className="pl-2">
                      {stop.products.bus ? (
                        <i className="fas fa-bus"></i>
                      ) : (
                        false
                      )}{" "}
                      {stop.products.tram ? (
                        <i className="fas fa-tram"></i>
                      ) : (
                        false
                      )}{" "}
                      {stop.products.suburban ? (
                        <i className="fas fa-train"></i>
                      ) : (
                        false
                      )}{" "}
                      {stop.products.subway ? (
                        <i className="fas fa-subway"></i>
                      ) : (
                        false
                      )}{" "}
                    </span>

                    <span
                      className="absolute right-6 bg-bg-600 rounded-lg px-1"
                      onClick={() => setFromStop(stop)}
                    >
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </div>
                </div>
              ))
            } 
            <button onClick={() => dispatch({type: 'clearFrom'})} className='bg-bg-400 text-white px-2 m-1 rounded-lg py-1' >Search Again</button>
            </div>: (
              <span>
                <input
                  className=""
                  type="text"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  placeholder="From"
                ></input>
                <input
                  className="text-white px-1 bg-bg-100 ml-1 border rounded-lg"
                  type="submit"
                  value="Find"
                  onClick={() => fromStopsFinder(from)}
                ></input>
                
              </span>
            )}
          </span>
        )}

        {/* To Start here */}
        <hr></hr>
        <p className="text-yellow-200 bg-bg-200 font-bold">Travelling To</p>

        {toStop.id.length > 1 ? (
          <p className="rounded-full border-solid border-2 border-bg-600 p-1.5 mx-auto bg-bg-300 text-white w-full m-1">
            {toStop.name} <i onClick={()=>setToStop({ id: "" })} className="absolute right-4 p-1 rounded-lg fas fa-trash-alt bg-bg-600"></i>
          </p>
        ) : (
          <span className="To  m-2">
            {state.stops.toStops.length > 0 ? <div>{
              state.stops.toStops.map((stop) => (
                <div
                  key={stop.id}
                  className="block rounded-full border-solid border-2 border-bg-600 p-1.5 mx-auto bg-bg-300 hover:bg-bg-500 text-white list-none"
                >
                  <div className="flex">
                    {stop.name.length > 25 ? (
                      <Marquee
                        style={{ width: "65%" }}
                        gradient={false}
                        delay={"3"}
                        pauseOnHover={true}
                      >
                        {"_" + stop.name + "_"}
                      </Marquee>
                    ) : (
                      <span style={{ width: "65%" }}>{stop.name}</span>
                    )}
                    <span className="pl-2">
                      {stop.products.bus ? (
                        <i className="fas fa-bus"></i>
                      ) : (
                        false
                      )}{" "}
                      {stop.products.tram ? (
                        <i className="fas fa-tram"></i>
                      ) : (
                        false
                      )}{" "}
                      {stop.products.suburban ? (
                        <i className="fas fa-train"></i>
                      ) : (
                        false
                      )}{" "}
                      {stop.products.subway ? (
                        <i className="fas fa-subway"></i>
                      ) : (
                        false
                      )}{" "}
                    </span>

                    <span
                      className="absolute right-6 bg-bg-600 rounded-lg px-1"
                      onClick={() => setToStop(stop)}
                    >
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </div>
                </div>
              ))
                      }
                      <button onClick={() => dispatch({type: 'clearTo'})} className='bg-bg-400 text-white px-2 m-1 rounded-lg py-1' >Search Again</button>
                      </div> : (
              <span>
                <input
                  className=""
                  type="text"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  placeholder="To"
                ></input>
                <input
                  className="text-white px-1 bg-bg-100 ml-1 border rounded-lg"
                  type="submit"
                  value="Find"
                  onClick={() => toStopsFinder(to)}
                ></input>
              </span>
            )}
          </span>
        )}
      </div>

      {fromStop.id.length > 1 && toStop.id.length > 1 ?
      <div>
  <div>
          <select name="day" id="day" onChange={(e)=>setDay(e.target.value)} className='p-1'>
    <option value="today">Today</option>
    <option value="tomorrow">Tomorrow</option>
  </select>
      <TimePicker
        onChange={(e)=>setTime(e)}
        value={time}
        className='bg-gray-300'
      />
    </div>
<div className="inline-block text-white px-3 border rounded-lg bg-bg-200 m-1" onClick={()=>planner()}>Search</div>
      </div>
      :
    <p></p>
    }
      </div>
      
    </div>
  );
};

export default JourneyPlanner;
