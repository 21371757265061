import { Link } from 'react-router-dom'

const Fav = ({state, dispatch}) => {

    const departures =  (e) => {
        fetch(`https://v5.bvg.transport.rest/stops/${e.id}/departures?duration=30&linesOfStops=false&remarks=true`, {method: 'GET', mode: 'cors', headers: {}})
        .then(response => response.json())
        .then(response => response.error ? dbDeparture(e) : dispatch({ type: 'departures', payLoad: response, stopName: e.name, stopId: e.id }))
        .catch(err => console.error(err));
      }
    const dbDeparture = (e) => {
      fetch(`https://v5.db.transport.rest/stops/${e.id}/departures?duration=30&linesOfStops=false&remarks=true`, {method: 'GET', mode: 'cors', headers: {}})
      .then(response => response.json())
      .then(response => dispatch({ type: 'departures', payLoad: response, stopName: e.name, stopId: e.id }))
      .catch(err => console.error(err));
    }
    
    return( 
        <div>
          <p className='underline underline-offset-2 rounded-full border-solid border-bg-600 p-1.5 mx-auto my-1 bg-bg-300 text-white'>My Favourite {state.favStop.length === 1 ? 'Stop' : 'Stops'}</p>
            {state.favStop.length > 0 ? state.favStop.map((stop, i) => <li  key={stop.id}  className="rounded-full relative flex border-solid border-2 border-bg-600 p-1.5 mx-auto my-1 bg-bg-300 hover:bg-bg-500 active:bg-bg-600 text-white list-none max-w-lg">
        <span className="">
          <Link to={`/departures`} onClick={() => departures(stop)} >
          {stop.name}{ ' ' }
          {stop.products.bus === true ? <i className="fas fa-bus"></i> : false}{ ' ' }
          {stop.products.tram === true ? <i className="fas fa-tram"></i> : false}{ ' ' }
          {stop.products.suburban === true ? <i className="fas fa-train"></i> : false}{ ' ' }
          {stop.products.subway === true ? <i className="fas fa-subway"></i> : false}{ ' ' }
          </Link>
        </span>
          <span onClick={(stop) => dispatch({type: 'remove', payLoad: i})} className="absolute right-2 bottom-1 bg-bg-600 rounded-lg p-0.5"><i className="far fa-trash-alt"></i></span>
          </li>) : <p className='rounded-full border-solid border-bg-600 p-1.5 mx-auto my-1 bg-bg-300 text-white'>No stops added</p> }
          <p className='underline underline-offset-2 rounded-full border-solid border-bg-600 p-1.5 mx-auto my-1 bg-bg-300 text-white'></p>
        </div> 
    )
}

export default Fav