import { Map, Marker } from "pigeon-maps"

const MyMap = ({ state }) => {
  return (
    <Map height={320} center={[state.latitude, state.longitude]} defaultZoom={13}>
      <Marker width={50} anchor={[state.latitude, state.longitude]} />
    </Map>
  );
}

export default MyMap